var svgPath = require('svgpath');
const epsilon = 0.00000001;

function minMaxQ(A: number[]) {
    var min = Math.min(A[0], A[2]);
    const max = Math.max(A[0], A[2]);

    if (A[1] > A[0] ? A[2] >= A[1] : A[2] <= A[1]) {
        // if no extremum in ]0,1[
        return [min, max];
    }
    // check if the extremum E is min or max
    var E = (A[0] * A[2] - A[1] * A[1]) / (A[0] - 2 * A[1] + A[2]);
    return E < min ? [E, max] : [min, E];
};


function minMaxC(A: number[]) {
    var K = A[0] - 3 * A[1] + 3 * A[2] - A[3];
    // if the polynomial is (almost) quadratic and not cubic
    if (Math.abs(K) < epsilon) {
        if (A[0] === A[3] && A[0] === A[1]) {
            // no curve, point targeting same location
            return [A[0], A[3]];
        }
        return minMaxQ([
            A[0],
            -0.5 * A[0] + 1.5 * A[1],
            A[0] - 3 * A[1] + 3 * A[2],
        ]);
    }
    // the reduced discriminant of the derivative
    var T = -A[0] * A[2] +
        A[0] * A[3] -
        A[1] * A[2] -
        A[1] * A[3] +
        A[1] * A[1] +
        A[2] * A[2];
    // if the polynomial is monotone in [0,1]
    if (T <= 0) {
        return [Math.min(A[0], A[3]), Math.max(A[0], A[3])];
    }
    var S = Math.sqrt(T);
    // potential extrema
    var min = Math.min(A[0], A[3]), max = Math.max(A[0], A[3]);
    var L = A[0] - 2 * A[1] + A[2];
    // check local extrema
    for (var R = (L + S) / K, i = 1; i <= 2; R = (L - S) / K, i++) {
        if (R > 0 && R < 1) {
            // if the extrema is for R in [0,1]
            var Q = A[0] * (1 - R) * (1 - R) * (1 - R) +
                A[1] * 3 * (1 - R) * (1 - R) * R +
                A[2] * 3 * (1 - R) * R * R +
                A[3] * R * R * R;
            if (Q < min) {
                min = Q;
            }
            if (Q > max) {
                max = Q;
            }
        }
    }
    return [min, max];
}

type PathSegment = string | number;
function svgPathBbox(path: string, scale: number) {
    const min = [Infinity, Infinity]
    const max = [-Infinity, -Infinity];

    svgPath(path)
        .abs()
        .unarc()
        .unshort()
        .iterate(function (seg: PathSegment[], _: number, x: number, y: number) {
            const command = seg[0] as string;
            const values = seg.slice(1) as number[];
            values.unshift(-1);

            switch (command) {
                case "M":
                case "L": {
                    if (min[0] > values[1]) {
                        min[0] = values[1];
                    }
                    if (min[1] > values[2]) {
                        min[1] = values[2];
                    }
                    if (max[0] < values[1]) {
                        max[0] = values[1];
                    }
                    if (max[1] < values[2]) {
                        max[1] = values[2];
                    }
                    break;
                }
                case "V": {
                    if (min[1] > values[1]) {
                        min[1] = values[1];
                    }
                    if (max[1] < values[1]) {
                        max[1] = values[1];
                    }
                    break;
                }
                case "H": {
                    if (min[0] > values[1]) {
                        min[0] = values[1];
                    }
                    if (max[0] < values[1]) {
                        max[0] = values[1];
                    }
                    break;
                }
                case "C": {
                    var cxMinMax = minMaxC([x, values[1], values[3], values[5]]);
                    if (min[0] > cxMinMax[0]) {
                        min[0] = cxMinMax[0];
                    }
                    if (max[0] < cxMinMax[1]) {
                        max[0] = cxMinMax[1];
                    }
                    var cyMinMax = minMaxC([y, values[2], values[4], values[6]]);
                    if (min[1] > cyMinMax[0]) {
                        min[1] = cyMinMax[0];
                    }
                    if (max[1] < cyMinMax[1]) {
                        max[1] = cyMinMax[1];
                    }
                    break;
                }
                case "Q": {
                    var qxMinMax = minMaxQ([x, values[1], values[3]]);
                    if (min[0] > qxMinMax[0]) {
                        min[0] = qxMinMax[0];
                    }
                    if (max[0] < qxMinMax[1]) {
                        max[0] = qxMinMax[1];
                    }
                    var qyMinMax = minMaxQ([y, values[2], values[4]]);
                    if (min[1] > qyMinMax[0]) {
                        min[1] = qyMinMax[0];
                    }
                    if (max[1] < qyMinMax[1]) {
                        max[1] = qyMinMax[1];
                    }
                    break;
                }
            }
    }, true);
    
    return { 
        left: Math.round(min[0] * scale), 
        top: Math.round(min[1] * scale), 
        width: Math.round((max[0] - min[0]) * scale),
        height: Math.round((max[1] - min[1]) * scale)
    };
}

const path = "M410.315,126.429c-5.7-24.1-16.7-43.2-32.7-56.7c-25-20.8-53.1-19.8-60.3-19.8h-95.8c-0.4-8.9-6.5-16.7-14.5-18.7 l-101.9-30.2c-15.5-4.8-25.5,8.1-26,19.8v100.9c0.5,12.2,9.6,22.4,26,20.8l102-30.2c8.3-3.1,14.6-10.4,14.6-19.8v-1 c3.7,0,8.3,0,13.5,0v65c-60.4,9.6-107.2,62.4-107.2,125.4v187.3c0,11.4,9.4,20.8,20.8,20.8h213.3c11.4,0,19.8-8.3,19.8-20.8v-187.3 c0-62.3-45.7-114.6-105.1-125v-65.4h42.7c0,0,17.7-1,31.2,10.4c9.1,7.6,15.5,19.1,19,34.1c2.4,10.2,12,17,22.4,16l0,0 C404.715,150.729,413.315,138.729,410.315,126.429z M180.015,77.029l-60.4,17.7v-46.8l60.3,18.7v10.4H180.015z M341.215,283.029 v166.5h-171.6h-1v-166.5c0-47.9,39.5-86.3,86.3-86.3C302.715,196.629,341.215,236.229,341.215,283.029z";
console.log(svgPathBbox(path, 1))

export { svgPathBbox };