import { Annotation, init, reset, zoom } from "./ignisCanvas";
import type { CSharpInstance, AnnotationLegendaItem } from "./ignisCanvas";

declare global {
    interface Window {
        initCanvas: (instance: CSharpInstance, firstRender: boolean, isLoading: boolean, backgroundImg: string, annotations: Annotation[], annotationLegendaItems: AnnotationLegendaItem[]) => void;
        zoom: (z: number) => void;
        reset: () => void;
        addAnnotation: (annotation: Annotation) => void;
        log: (obj: any) => void;
        exportToJson: (json: string, title: string) => void;
        scrollToLastField: () => void;
        saveAs: (name: string, base64: string) => void;
        updateBackgroundImage: (backgroundImg: string) => void;
        showLoader: () => void;
    }
}

window.initCanvas = init;
window.zoom = zoom;
window.reset = reset;
window.log = console.log;


window.exportToJson = (json, title) => {
    const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(json);
    const downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", `${title}.json`);
    document.body.appendChild(downloadAnchorNode);
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
}

window.scrollToLastField = () => {
    const form = document.getElementById("ignis-form");
    if (!form) return;

    form.scrollTop = form.scrollHeight;
}

window.saveAs = (name, base64) => {
    var link = document.createElement('a');
    link.download = name;
    link.href = "data:application/octet-stream;base64," + base64;
    document.body.appendChild(link); // Needed for Firefox
    link.click();
    document.body.removeChild(link);
}