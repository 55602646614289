import { svgPathBbox } from "./svgPathHelper";

export interface Annotation {
    annotationId: string;
    x: number;
    y: number;
    svg: string;
    color: string;
    scale: number;
    description: string;
}

interface Point {
    x: number;
    y: number;
}

interface CSharpInstance {
    _id: number;
    invokeMethodAsync: (func: string, ...params: any[]) => void;
}

interface AnnotationLegendaItem {
    id: number,
    description: string,
    color: string;
}


class IgnisCanvas {
    private svgElement = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    private svgMatrix = this.svgElement.createSVGMatrix();

    private annotations: Annotation[] = [];
    private annotationLegendaItems: AnnotationLegendaItem[] = [];
    private background = new Image();
    private offset: Point = { x: 0, y: 0 };
    private previousPoint: Point | null = null;
    private activeAnnotationId: string | null = null;

    private zoom = 1;
    private imageScale = 1;
    private isDragging = false;
    private isClicked = false;


    constructor(private canvas: HTMLCanvasElement, private instance: CSharpInstance) {
        this.canvas.addEventListener('mousedown', this.onMouseDown.bind(this));
        this.canvas.addEventListener('mousemove', this.onMouseMove.bind(this));
        this.canvas.addEventListener('mouseup', this.onMouseUp.bind(this));
        this.canvas.addEventListener('wheel', this.onWheel.bind(this));

        this.background.onload = () => {
            this.draw();
        };
    }

    public updateZoom(v: number) {
        this.zoom = this.clampZoom(this.zoom + v);
        this.draw();
    }

    public reset() {
        this.offset = { x: 0, y: 0 };
        this.zoom = 1;
        this.draw();
    }

    public setAnnotationLegendaItems(annotationLegendaItems: AnnotationLegendaItem[]) {
        this.annotationLegendaItems = annotationLegendaItems;
    }

    public setAnnotations(annotations: Annotation[]) {
        this.annotations = annotations;
        this.draw();
    }

    public updateBackgroundImage(backgroundImg: string) {
        if(backgroundImg == null) return;
        this.background.src = backgroundImg;
        const canvasContainer = document.getElementById("canvas-container");
        if(canvasContainer!= null) {
            this.canvas.width = canvasContainer.offsetWidth;
        }
        this.draw();
    }

    public showLoader(isLoading: boolean) {
        if(isLoading) {
            const canvasContainer = document.getElementById("canvas-container");
            if(canvasContainer!= null) {
                this.canvas.width = canvasContainer.offsetWidth;
            }
            this.drawLoadingText();
        }
    }

    // Helper functions
    private clampZoom = (v: number) => Math.min(Math.max(v, 0.5), 5);
    private relativeX = (clientX: number, left: number, x: number) => Math.round(((clientX - left) / this.zoom + this.offset.x / this.zoom) - x);
    private relativeY = (clientY: number, top: number, y: number) => Math.round(((clientY - top) / this.zoom + this.offset.y / this.zoom) - y);
    private isCoordinateInBox = (x: number, y: number, left: number, top: number, width: number, height: number) => x >= left && x <= left + width && y >= top && y <= top + height;
    
    private getRelativePoint = (clientX: number, clientY: number) => {
        const { left, top } = this.canvas.getBoundingClientRect();

        const x = (this.canvas.width / this.zoom - this.background.width * this.imageScale) / 2;
        const y = (this.canvas.height / this.zoom - this.background.height * this.imageScale) / 2;

        return {
            x: this.relativeX(clientX, left, x),
            y: this.relativeY(clientY, top, y)
        }
    }

    private findActiveAnnotationId = (clientX: number, clientY: number) => {
        const annotation = this.annotations.find(annotation => {
            const {
                left,
                top,
                width,
                height
            } = svgPathBbox(annotation.svg, annotation.scale);

            const { x, y } = this.getRelativePoint(clientX, clientY);
            return this.isCoordinateInBox(x, y, (annotation.x * this.imageScale + left) - width / 2, (annotation.y * this.imageScale + top) - height / 2, width, height);
        });

        return annotation?.annotationId ?? null;
    }

    // Eventhandlers
    private onMouseMove = (event: MouseEvent) => {
        const { clientX, clientY } = event;
        const tooltip = document.getElementById('tooltip') as HTMLElement;


        // Annotation On Hover
        if (!this.isClicked && !this.isDragging) {
            this.activeAnnotationId = this.findActiveAnnotationId(clientX, clientY);
            this.canvas.style.cursor = 'default';

            if (this.activeAnnotationId === null) {
                tooltip.style.display = 'none';
                return;
            }

            let annotationIndex = this.annotations.findIndex(annotation => annotation.annotationId === this.activeAnnotationId)

            tooltip.style.display = 'block';
            tooltip.style.left = `${event.clientX + 10}px`;
            tooltip.style.top = `${event.clientY + 10}px`;
            tooltip.innerText = this.annotations[annotationIndex].description;

            this.canvas.style.cursor = 'pointer';
            this.instance.invokeMethodAsync('OnAnnotationHover', this.activeAnnotationId);
        }        
        
        // Drag
        if (!this.isClicked) return;
        const { x, y } = this.previousPoint ?? { x: 0, y: 0 };
        
        const deltaX = x - clientX;
        const deltaY = y - clientY;

        if (Math.abs(deltaX) < 5 && Math.abs(deltaY) < 5) return;
        this.isDragging = true;

        // Drag Annotation
        // if (this.activeAnnotationId !== null) {
        //     const { x, y } = this.getRelativePoint(clientX, clientY);
        //     const index = this.annotations.findIndex(annotation => annotation.id === this.activeAnnotationId);
        //     this.annotations[index].x = x;
        //     this.annotations[index].y = y;
        // }
        // else {
            this.offset.x += deltaX;
            this.offset.y += deltaY;
            this.previousPoint = { x: clientX, y: clientY };
        // }

        this.draw();
        event.preventDefault();
    }

    private onMouseDown = (event: MouseEvent) => {
        const { clientX, clientY } = event;
        this.isClicked = true;
        this.previousPoint = { x: clientX, y: clientY };
        event.preventDefault();
    }

    private onMouseUp = (event: MouseEvent) => {
        const { clientX, clientY } = event;
        const { x, y } = this.getRelativePoint(clientX, clientY);
        if (this.activeAnnotationId !== null && !this.isDragging) {
            this.instance.invokeMethodAsync('OnAnnotationClick', this.activeAnnotationId);
        }
        else if (!this.isDragging)
            this.instance.invokeMethodAsync('LayoutOnClick', x, y);

        this.isDragging = false;
        this.isClicked = false;
        event.preventDefault();
    }

    private onWheel = (event: WheelEvent) => {
        const { deltaY } = event;
        const direction = deltaY > 0 ? -1 : 1;
        this.updateZoom(direction * 0.1);
        event.preventDefault();
    }

    private draw() {
        const ctx = this.canvas.getContext('2d');
        if (ctx === null) return;

        this.canvas.height = 800;

        var imagescale = Math.floor(Math.min(this.canvas.width / this.background.width, this.canvas.height / this.background.height) * 100) / 100;

        if(isFinite(imagescale)) {
            this.imageScale = imagescale;
        } else {
        }

        ctx.translate(-this.offset.x, -this.offset.y);
        ctx.scale(this.zoom, this.zoom);
        ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

        const x = (this.canvas.width / this.zoom - this.background.width * this.imageScale) / 2;
        const y = (this.canvas.height / this.zoom - this.background.height * this.imageScale) / 2;

        ctx.drawImage(this.background, x, y, this.background.width * this.imageScale, this.background.height * this.imageScale);
        
        this.annotations.forEach((annotation: Annotation) => {
            const svg = new Path2D(annotation.svg);
            const path = new Path2D();

            annotation.scale = 0.075;
            const scaled = this.svgMatrix.scale(annotation.scale);
            path.addPath(svg, scaled);

            const {
                width,
                height
            } = svgPathBbox(annotation.svg, annotation.scale);

            ctx.save();
            ctx.fillStyle = annotation.color;

            const annotationX = (annotation.x * this.imageScale + x) - width / 2;
            const annotationY = (annotation.y * this.imageScale + y) - height / 2;
            ctx.translate(annotationX, annotationY);
            ctx.fill(path);
            ctx.restore();
        });
        this.drawLegend(ctx, this.annotationLegendaItems, this.canvas.width);
    }

    private drawLoadingText() {
        const ctx = this.canvas.getContext('2d');
        if (ctx === null) return;
        
        this.canvas.height = 800;

        const text = "Aan het laden...";
        const textWidth = ctx.measureText(text).width;
    
        // Calculate the center coordinates
        const centerX = this.canvas.width / 2;
        const centerY = this.canvas.height / 2;
    
        // Set the font and fill style
        ctx.font = "20px Arial";
        ctx.fillStyle = "black";
    
        // Center the text horizontally and vertically
        ctx.fillText(text, centerX - textWidth / 2, centerY);
    }

    private drawLegend(
        ctx: CanvasRenderingContext2D,
        annotationLegendaItems: AnnotationLegendaItem[],
        canvasWidth: number,
        legendWidth: number = 225,
        legendHeight: number = 100,
        spacing: number = 10
    ) {
        // Calculate the top-right corner coordinates for the legend
        const legendX = canvasWidth - legendWidth - 10;
        const legendY = 10;

        // Draw the legend background
        ctx.fillStyle = 'rgba(255, 255, 255, 0.8)';
        ctx.fillRect(legendX, legendY, legendWidth, legendHeight);

          // Calculate the updated itemHeight with spacing
        const totalSpacing = (annotationLegendaItems.length - 1) * spacing;
        const availableHeight = legendHeight - totalSpacing;
        const itemHeight = availableHeight / annotationLegendaItems.length;
    
        // Draw legend items
        ctx.font = '12px Arial';
        ctx.textBaseline = 'middle';
    
        for (let i = 0; i < annotationLegendaItems.length; i++) {
            const item = annotationLegendaItems[i];
            const itemY = legendY + i * (itemHeight + (i == 0 ? 0 : spacing) ) + itemHeight / 2;
                
            // Draw colored rectangle
            ctx.fillStyle = item.color;
            ctx.fillRect(legendX + 10, itemY - 10, 20, 20);
                
            // Draw legend label
            ctx.fillStyle = 'black';
            ctx.fillText(item.description, legendX + 40, itemY);
        }
    }
}

var canvas: IgnisCanvas | null = null;
const init = (instance: CSharpInstance, firstRender: boolean, isLoading: boolean, backgroundImg: string, annotations: Annotation[], annotationLegendaItems: AnnotationLegendaItem[]) => {
    if (canvas === null || firstRender) {
        const canvasElement = document.getElementById('layout-canvas') as HTMLCanvasElement;
        canvas = new IgnisCanvas(canvasElement, instance);
    } else {
        canvas.setAnnotationLegendaItems(annotationLegendaItems);
        canvas.setAnnotations(annotations);
        canvas.showLoader(isLoading);
        canvas.updateBackgroundImage(backgroundImg);
    }
}

const zoom = (z: number) => {
    if (canvas === null) return;
    canvas.updateZoom(z);
}

const reset = () => {
    if (canvas === null) return;
    canvas.reset();
}

export { init, zoom, reset };
export type { CSharpInstance, AnnotationLegendaItem };